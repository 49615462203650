<template>
  <b-container fluid>
    <b-form style="background: #fff; padding:20px">
      <b-form @submit.prevent="saveProgram()">
        <b-row style="margin-bottom: 50px; margin-top: 20px">
          <div class="col-sm-12" >
            <h4>{{ $t('program.company_program') }}</h4><br>
          </div>
          <div class="row col-sm-12">
            <div class="col-sm-6">
              <input type="checkbox" id="monday" value="1" v-model="monday">
              <label for="monday" style="padding: 0px 10px;">{{ $t('program.monday') }}</label>
              <div v-if="monday === true" style="margin-bottom: 20px">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('program.start_time')">
                    <b-form-input type="time" value="09:00" v-model="monday_pstart"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('program.end_time')">
                    <b-form-input type="time" value="17:00" v-model="monday_pend"></b-form-input>
                  </b-form-group>
                </b-row>
              </div>
            </div>
            <div class="col-sm-6" v-show="monday === true">
              <input type="checkbox" id="monday_break" value="1" v-model="monday_break" >
              <label for="monday_break" style="padding: 0px 10px;">{{ $t('calendar.break') }}</label>
              <div v-if="monday_break == true" style="margin-bottom: 20px">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('program.break_start')">
                    <b-form-input type="time" value="12:00" v-model="monday_bstart"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('program.break_end')">
                    <b-form-input type="time" value="13:00" v-model="monday_bend"></b-form-input>
                  </b-form-group>
                </b-row>
              </div>
            </div>
          </div>
          <div class="row col-sm-12" >
            <div class="col-sm-6">
              <input type="checkbox" id="tuesday" value="2" v-model="tuesday">
              <label for="tuesday" style="padding: 0px 10px;">{{ $t('program.tuesday') }}</label>
              <div v-if="tuesday == true" style="margin-bottom: 20px">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('program.start_time')">
                    <b-form-input type="time" value="09:00" v-model="tuesday_pstart"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('program.end_time')">
                    <b-form-input type="time" value="17:00" v-model="tuesday_pend"></b-form-input>
                  </b-form-group>
                </b-row>
              </div>
            </div>
            <div class="col-sm-6" v-if="tuesday == true">
              <input type="checkbox" id="tuesday_break" value="2" v-model="tuesday_break" >
              <label for="tuesday_break" style="padding: 0px 10px;">{{ $t('calendar.break') }}</label>
              <div v-if="tuesday_break == true" style="margin-bottom: 20px">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('program.break_start')">
                    <b-form-input type="time" value="12:00" v-model="tuesday_bstart"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('program.break_end')">
                    <b-form-input type="time" value="13:00" v-model="tuesday_bend"></b-form-input>
                  </b-form-group>
                </b-row>
              </div>
            </div>
          </div>
          <div class="row col-sm-12" >
            <div class="col-sm-6">
              <input type="checkbox" id="wednesday" value="3" v-model="wednesday">
              <label for="wednesday" style="padding: 0px 10px;">{{ $t('program.wednesday') }}</label>
              <div v-if="wednesday == true" style="margin-bottom: 20px">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('program.start_time')">
                    <b-form-input type="time" value="09:00" v-model="wednesday_pstart"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('program.end_time')">
                    <b-form-input type="time" value="17:00" v-model="wednesday_pend"></b-form-input>
                  </b-form-group>
                </b-row>
              </div>
            </div>
            <div class="col-sm-6" v-if="wednesday == true">
              <input type="checkbox" id="wednesday_break" value="3" v-model="wednesday_break" >
              <label for="wednesday_break" style="padding: 0px 10px;">{{ $t('calendar.break') }}</label>
              <div v-if="wednesday_break == true" style="margin-bottom: 20px">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('program.break_start')">
                    <b-form-input type="time" value="12:00" v-model="wednesday_bstart"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('program.break_end')">
                    <b-form-input type="time" value="13:00" v-model="wednesday_bend"></b-form-input>
                  </b-form-group>
                </b-row>
              </div>
            </div>
          </div>
          <div class="row col-sm-12" >
            <div class="col-sm-6">
              <input type="checkbox" id="thursday" value="4" v-model="thursday">
              <label for="thursday" style="padding: 0px 10px;">{{ $t('program.thursday') }}</label>
              <div v-if="thursday == true" style="margin-bottom: 20px">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('program.start_time')">
                    <b-form-input type="time" value="09:00" v-model="thursday_pstart"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('program.end_time')">
                    <b-form-input type="time" value="17:00" v-model="thursday_pend"></b-form-input>
                  </b-form-group>
                </b-row>
              </div>
            </div>
            <div class="col-sm-6" v-if="thursday == true">
              <input type="checkbox" id="thursday_break" value="4" v-model="thursday_break" >
              <label for="thursday_break" style="padding: 0px 10px;">{{ $t('calendar.break') }}</label>
              <div v-if="thursday_break == true" style="margin-bottom: 20px">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('program.break_start')">
                    <b-form-input type="time" value="12:00" v-model="thursday_bstart"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('program.break_end')">
                    <b-form-input type="time" value="13:00" v-model="thursday_bend"></b-form-input>
                  </b-form-group>
                </b-row>
              </div>
            </div>
          </div>
          <div class="row col-sm-12" >
            <div class="col-sm-6">
              <input type="checkbox" id="friday" value="5" v-model="friday">
              <label for="friday" style="padding: 0px 10px;">{{ $t('program.friday') }}</label>
              <div v-if="friday == true" style="margin-bottom: 20px">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('program.start_time')">
                    <b-form-input type="time" value="09:00" v-model="friday_pstart"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('program.end_time')">
                    <b-form-input type="time" value="17:00" v-model="friday_pend"></b-form-input>
                  </b-form-group>
                </b-row>
              </div>
            </div>
            <div class="col-sm-6" v-if="friday == true">
              <input type="checkbox" id="friday_break" value="5" v-model="friday_break" >
              <label for="friday_break" style="padding: 0px 10px;">{{ $t('calendar.break') }}</label>
              <div v-if="friday_break == true" style="margin-bottom: 20px">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('program.break_start')">
                    <b-form-input type="time" value="12:00" v-model="friday_bstart"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('program.break_end')">
                    <b-form-input type="time" value="13:00" v-model="friday_bend"></b-form-input>
                  </b-form-group>
                </b-row>
              </div>
            </div>
          </div>
          <div class="row col-sm-12" >
            <div class="col-sm-6">
              <input type="checkbox" id="saturday" value="6" v-model="saturday">
              <label for="saturday" style="padding: 0px 10px;">{{ $t('program.saturday') }}</label>
              <div v-if="saturday == true" style="margin-bottom: 20px">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('program.start_time')">
                    <b-form-input type="time" value="09:00" v-model="saturday_pstart"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('program.end_time')">
                    <b-form-input type="time" value="17:00" v-model="saturday_pend"></b-form-input>
                  </b-form-group>
                </b-row>
              </div>
            </div>
            <div class="col-sm-6" v-if="saturday == true">
              <input type="checkbox" id="saturday_break" value="6" v-model="saturday_break" >
              <label for="saturday_break" style="padding: 0px 10px;">{{ $t('calendar.break') }}</label>
              <div v-if="saturday_break == true" style="margin-bottom: 20px">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('program.break_start')">
                    <b-form-input type="time" value="12:00" v-model="saturday_bstart"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('program.break_end')">
                    <b-form-input type="time" value="13:00" v-model="saturday_bend"></b-form-input>
                  </b-form-group>
                </b-row>
              </div>
            </div>
          </div>
          <div class="row col-sm-12" >
            <div class="col-sm-6">
              <input type="checkbox" id="sunday" value="7" v-model="sunday">
              <label for="sunday" style="padding: 0px 10px;">{{ $t('program.sunday') }}</label>
              <div v-if="sunday == true" style="margin-bottom: 20px">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('program.start_time')">
                    <b-form-input type="time" value="09:00" v-model="sunday_pstart"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('program.end_time')">
                    <b-form-input type="time" value="17:00" v-model="sunday_pend"></b-form-input>
                  </b-form-group>
                </b-row>
              </div>
            </div>
            <div class="col-sm-6" v-if="sunday == true">
              <input type="checkbox" id="sunday_break" value="7" v-model="sunday_break" >
              <label for="sunday_break" style="padding: 0px 10px;">{{ $t('calendar.break') }}</label>
              <div v-if="sunday_break == true" style="margin-bottom: 20px">
                <b-row>
                  <b-form-group class="col-md-6" :label="$t('program.break_start')">
                    <b-form-input type="time" value="12:00" v-model="sunday_bstart"></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-6" :label="$t('program.break_end')">
                    <b-form-input type="time" value="13:00" v-model="sunday_bend"></b-form-input>
                  </b-form-group>
                </b-row>
              </div>
            </div>
          </div>
        </b-row>
        <b-button type="submit" variant="primary" class="mr-2">{{ $t('program.submit') }}</b-button>
      </b-form>
    </b-form>
  </b-container>
</template>

<script>
import Axios from 'axios'
import { API } from '@/config/api.config'
import { core } from '@/config/pluginInit'

export default {
  name: 'Program',
  mounted () {
    core.index()
    this.getCurrentProgram()
  },
  data () {
    return {
      apiToken: localStorage.getItem('api_token') || '',
      monday: '',
      monday_break: '',
      monday_bstart: '12:00',
      monday_pstart: '09:00',
      monday_bend: '13:00',
      monday_pend: '17:00',
      tuesday: '',
      tuesday_break: '',
      tuesday_bstart: '12:00',
      tuesday_pstart: '09:00',
      tuesday_bend: '13:00',
      tuesday_pend: '17:00',
      wednesday: '',
      wednesday_break: '',
      wednesday_bstart: '12:00',
      wednesday_pstart: '09:00',
      wednesday_bend: '13:00',
      wednesday_pend: '17:00',
      thursday: '',
      thursday_break: '',
      thursday_bstart: '12:00',
      thursday_pstart: '09:00',
      thursday_bend: '13:00',
      thursday_pend: '17:00',
      friday: '',
      friday_break: '',
      friday_bstart: '12:00',
      friday_pstart: '09:00',
      friday_bend: '13:00',
      friday_pend: '17:00',
      saturday: '',
      saturday_break: '',
      saturday_bstart: '12:00',
      saturday_pstart: '09:00',
      saturday_bend: '13:00',
      saturday_pend: '17:00',
      sunday: '',
      sunday_break: '',
      sunday_bstart: '12:00',
      sunday_pstart: '09:00',
      sunday_bend: '13:00',
      sunday_pend: '17:00',
      sms: '',
      email: '',
      sms_1: '',
      sms_2: '',
      email_1: '',
      email_2: ''
    }
  },
  methods: {
    getCurrentProgram () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.get(API.API_GET_COMPANY_PROGRAM)
        .then((response) => {
          if (response.data.status === 'success') {
            const currentProgram = JSON.parse(response.data.program.program)
            this.monday = currentProgram.monday
            this.monday_bstart = currentProgram.monday_bstart
            this.monday_pstart = currentProgram.monday_pstart
            this.monday_break = currentProgram.monday_break
            this.monday_bend = currentProgram.monday_bend
            this.monday_pend = currentProgram.monday_pend
            this.tuesday = currentProgram.tuesday
            this.tuesday_bstart = currentProgram.tuesday_bstart
            this.tuesday_pstart = currentProgram.tuesday_pstart
            this.tuesday_break = currentProgram.tuesday_break
            this.tuesday_bend = currentProgram.tuesday_bend
            this.tuesday_pend = currentProgram.tuesday_pend
            this.wednesday = currentProgram.wednesday
            this.wednesday_bstart = currentProgram.wednesday_bstart
            this.wednesday_pstart = currentProgram.wednesday_pstart
            this.wednesday_break = currentProgram.wednesday_break
            this.wednesday_bend = currentProgram.wednesday_bend
            this.wednesday_pend = currentProgram.wednesday_pend
            this.thursday = currentProgram.thursday
            this.thursday_bstart = currentProgram.thursday_bstart
            this.thursday_pstart = currentProgram.thursday_pstart
            this.thursday_break = currentProgram.thursday_break
            this.thursday_bend = currentProgram.thursday_bend
            this.thursday_pend = currentProgram.thursday_pend
            this.friday = currentProgram.friday
            this.friday_bstart = currentProgram.friday_bstart
            this.friday_pstart = currentProgram.friday_pstart
            this.friday_break = currentProgram.friday_break
            this.friday_bend = currentProgram.friday_bend
            this.friday_pend = currentProgram.friday_pend
            this.saturday = currentProgram.saturday
            this.saturday_bstart = currentProgram.saturday_bstart
            this.saturday_pstart = currentProgram.saturday_pstart
            this.saturday_break = currentProgram.saturday_break
            this.saturday_bend = currentProgram.saturday_bend
            this.saturday_pend = currentProgram.saturday_pend
            this.sunday = currentProgram.sunday
            this.sunday_bstart = currentProgram.sunday_bstart
            this.sunday_pstart = currentProgram.sunday_pstart
            this.sunday_break = currentProgram.sunday_break
            this.sunday_bend = currentProgram.sunday_bend
            this.sunday_pend = currentProgram.sunday_pend
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    saveProgram () {
      const program = {
        monday: this.monday,
        monday_bstart: this.monday_bstart,
        monday_pstart: this.monday_pstart,
        monday_break: this.monday_break,
        monday_bend: this.monday_bend,
        monday_pend: this.monday_pend,
        tuesday: this.tuesday,
        tuesday_bstart: this.tuesday_bstart,
        tuesday_pstart: this.tuesday_pstart,
        tuesday_break: this.tuesday_break,
        tuesday_bend: this.tuesday_bend,
        tuesday_pend: this.tuesday_pend,
        wednesday: this.wednesday,
        wednesday_bstart: this.wednesday_bstart,
        wednesday_pstart: this.wednesday_pstart,
        wednesday_break: this.wednesday_break,
        wednesday_bend: this.wednesday_bend,
        wednesday_pend: this.wednesday_pend,
        thursday: this.thursday,
        thursday_bstart: this.thursday_bstart,
        thursday_pstart: this.thursday_pstart,
        thursday_break: this.thursday_break,
        thursday_bend: this.thursday_bend,
        thursday_pend: this.thursday_pend,
        friday: this.friday,
        friday_bstart: this.friday_bstart,
        friday_pstart: this.friday_pstart,
        friday_break: this.friday_break,
        friday_bend: this.friday_bend,
        friday_pend: this.friday_pend,
        saturday: this.saturday,
        saturday_bstart: this.saturday_bstart,
        saturday_pstart: this.saturday_pstart,
        saturday_break: this.saturday_break,
        saturday_bend: this.saturday_bend,
        saturday_pend: this.saturday_pend,
        sunday: this.sunday,
        sunday_bstart: this.sunday_bstart,
        sunday_pstart: this.sunday_pstart,
        sunday_break: this.sunday_break,
        sunday_bend: this.sunday_bend,
        sunday_pend: this.sunday_pend
      }
      const formData = {
        program: program
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_ADD_COMPANY_PROGRAM, formData)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('program.company_updated'))
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
